import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Link from 'utils/localized-link';
//import Cookies from 'universal-cookie';
import Cookies from './../../../node_modules/universal-cookie';

import { navigate } from 'gatsby';

import UserApi from '@youship/api/user';

import SessionStorageManager from '@youship/utils/SessionStorageManager';

import AvatarButton from '@youship/components/objects/avatar-button';
import Dropdown from '@youship/components/objects/dropdown';
import Button from '@youship/components/objects/button';
import OffScreenSlider from '@youship/components/objects/off-screen-slider';
import LocaleSelect from 'components/locale-select';
import helper from '@youship/utils/helper';
 
import menuIcon from '@youship/assets/images/icons/menu.svg';

import avatarImage from '@youship/assets/images/avatar.jpg';
import logo from '@youship/assets/images/logo.svg';
import closeIcon from '../../images/icons/close.svg';

import locales from '@youship/i18n/locales';

import './styles.scss';

const API_BASE_URL = process.env.NODE_ENV === 'development' ?
  process.env.GATSBY_API_URL_DEVELOPMENT :
  process.env.GATSBY_API_URL_PRODUCTION;

const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const DROPDOWN_HORIZONTAL_POSITION_LEFT = 'left';
const DROPDOWN_HORIZONTAL_POSITION_RIGHT = 'right';
const MOBILE_BREAKPOINT = 576;

const cookies = new Cookies();

const COOKIES_DOMAIN = process.env.GATSBY_COOKIES_DOMAIN;

const allCookies = cookies.getAll();
const { AUTH_TOKEN } = allCookies;

const Navbar = ({ locale }) => {
  const [componentHasMounted, setComponentHasMounted] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(!!AUTH_TOKEN);
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userImageUrl, setUserImageUrl] = useState(null);

  // Menu dropdown
  const [showMenuDropdown, setShowMenuDropdown] = useState(false);
  const [horizontalPosition, setHorizontalPosition] = useState(typeof window !== 'undefined' && window.innerWidth < MOBILE_BREAKPOINT ?
    DROPDOWN_HORIZONTAL_POSITION_RIGHT :
    DROPDOWN_HORIZONTAL_POSITION_LEFT);
  const [showLanguageOptionsList, setShowLanguageOptionsList] = useState(false);
  const [showMobileMenuSlider, setShowMobileMenuSlider] = useState(false);
  
  const currentLanguageIcon = helper.getFlagByLocale(locale);

  const currentLanguageText = locales[locale]?.locale;

  const [isPageScrolled, setIsPageScrolled] = useState(false);

  const handleMenuTogglerClick = () => {
    setShowMenuDropdown(true);
  };

  const handleDropdownClose = () => {
    setShowMenuDropdown(false);
  };

  const handleMenuTogglerKeyDown = (event) => {
    if (event.keyCode === 13) {
      setShowMenuDropdown(!showMenuDropdown);
    }
  };

  const handleShowLanguageOptionsList = () => {
    // We need setTimeout to prevent the menu from closing
    setTimeout(() => {
      setShowLanguageOptionsList(true);
    }, 0);
  };

  const handleHideLanguageOptionsList = () => {
    // We need setTimeout to prevent the menu from closing
    setTimeout(() => {
      setShowLanguageOptionsList(false);
    }, 0);
  };

  const handleMobileMenuClose = () => {
    setShowMobileMenuSlider(false);
  };

  const handleMobileMenuTogglerClick = () => {
    // We need setTimeout to prevent the menu from closing
    setTimeout(() => {
      setShowMobileMenuSlider(true);
    }, 1);
  };

  const handleMobileMenuTogglerKeyDown = (event) => {
    if (event.keyCode === 13) {
      setShowMobileMenuSlider(!showMobileMenuSlider);
    }
  };

  const handlePageScroll = () => {
    if (typeof window !== 'undefined' && window.pageYOffset === 0) {
      setIsPageScrolled(false);
    } else if (!isPageScrolled) {
      setIsPageScrolled(true);
    }
  };

  const defaultLocaleKey = 'en';

  const handleLanguageChange = (value) => {
    if (value !== locale) {
      const { location } = window;
      let locationPathname = location.pathname;

      if (locale !== defaultLocaleKey) locationPathname = locationPathname.replace(`/${locale}`, '');

      let newLocation = `${locationPathname}${locationPathname.endsWith('/') ? '' : '/'}`;

      if (value !== defaultLocaleKey) newLocation = `/${value}${newLocation}`;

      navigate(newLocation);
    }
  };

  useEffect(
    () => {
      setComponentHasMounted(true);

      let lang = SessionStorageManager.getLocaleCookie();

      if (!lang) {
        lang = SessionStorageManager.getLocale();

        if (lang) {
          SessionStorageManager.setLocale(lang);
          handleLanguageChange(lang);
        } else {
          SessionStorageManager.setLocale(locale);
        }
      } else if (locale !== lang) {
        const { location } = window;
        const locationPathname = location.pathname;

        if (locationPathname === '/') {
          SessionStorageManager.setLocale(lang);
          handleLanguageChange(lang);
        }
      }

      if (typeof window !== 'undefined') {
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handlePageScroll);
      }

      SessionStorageManager.setApiBaseUrl(API_BASE_URL);

      if (AUTH_TOKEN) {
        SessionStorageManager.setAuthenticationToken(AUTH_TOKEN);
        setIsUserAuthenticated(true);

        const userProfile = cookies.get('USER');

        if (userProfile) {
          if (userProfile.firstName) setUserName(userProfile.firstName);
          if (userProfile.email) setUserEmail(userProfile.email);
          if (userProfile.photoUrl) setUserImageUrl(userProfile.photoUrl);
        } else {
          UserApi.resume()
            .then((response) => {
              if (response?.user) {
                const { firstname: firstName, email, photo_url: photoUrl } = response.user;

                if (firstName || email || photoUrl) {
                  cookies.set('USER', { email, firstName, photoUrl }, { path: '/', domain: COOKIES_DOMAIN });

                  if (userName !== firstName) setUserName(firstName);
                  if (userEmail !== email) setUserEmail(email);
                  if (userImageUrl !== photoUrl) setUserImageUrl(photoUrl);
                }
              }

              return response;
            })
            .catch((error) => {
              // TODO: Set proper error handling
              // eslint-disable-next-line no-console
              console.log(error);
            });
        }
      } else {
        SessionStorageManager.removeAuthenticationToken();
        setIsUserAuthenticated(false);
      }

      return () => {
        if (typeof window !== 'undefined') {
          window.addEventListener('scroll', handlePageScroll);
          window.removeEventListener('resize', handleResize);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleResize = () => {
    if (typeof window !== 'undefined' && window.innerWidth < MOBILE_BREAKPOINT) {
      setHorizontalPosition(DROPDOWN_HORIZONTAL_POSITION_RIGHT);
    } else {
      setHorizontalPosition(DROPDOWN_HORIZONTAL_POSITION_LEFT);
    }
  };

  const navbarClassNames = ['navbar'];

  if (!isUserAuthenticated) navbarClassNames.push('navbar--authentication');

  return (
    <>
      <header className={navbarClassNames.join(' ')}>
        <div className="container">
          <div className="navbar__content">

            <div className="navbar__logo-wrapper order-1 order-md-1">
              <Link
                className="navbar__logo-link"
                to="/"
              >
                <img
                  alt="logo"
                  className="navbar__logo"
                  src={logo}
                />
              </Link>
            </div>

            <div className="navbar__menu-container order-2 order-md-2">
              
              <div className="navbar__items-container d-none d-md-flex">
                <Button
                  linkComponent={Link}
                  linkProps={{ to: '/' }}
                  noArrow
                  noBackground
                  noNewTab
                  small
                >
                  <FormattedMessage id="header.navigation_links.home" />
                </Button>
                <Button
                  linkComponent={Link}
                  linkProps={{ to: '/expertise' }}
                  noArrow
                  noBackground
                  noNewTab
                  small
                >
                  <FormattedMessage id="header.navigation_links.expertise" />
                </Button>
                <Button
                  linkComponent={Link}
                  linkProps={{ to: '/about' }}
                  noArrow
                  noBackground
                  noNewTab
                  small
                >
                  <FormattedMessage id="header.navigation_links.about" />
                </Button>
                <Button
                  linkComponent={Link}
                  linkProps={{ to: '/contacts' }}
                  noArrow
                  noBackground
                  noNewTab
                  small
                >
                  <FormattedMessage id="header.navigation_links.contact" />
                </Button>
              </div>
            
              <div className="navbar__items-container navbar__items-container--login d-none d-md-flex">
                {/* Wait for component mount for proper hydration of the authentication buttons (dependent of authentication status) */}
                {componentHasMounted ? (
                  <>
                    {isUserAuthenticated && userName && (
                      <div className="navbar__account-button-wrapper">
                        <a href={WEB_APP_URL}>
                          <AvatarButton
                            imageUrl={userImageUrl || avatarImage}
                            name={userName}
                            text={(
                              <FormattedMessage
                                id="header.greeting"
                                values={{ name: userName }}
                              />
                            )}
                          />
                        </a>
                      </div>
                    )}
                    {isUserAuthenticated && !userName && (
                      <Button
                        context="primary"
                        external
                        linkComponent={Link}
                        linkProps={{ to: `${WEB_APP_URL}/logout` }}
                        noArrow
                        noNewTab
                        noShadow
                        outline
                        small
                      >
                        <FormattedMessage id="header.sign_out" />
                      </Button>
                    )}
                    {!isUserAuthenticated && (
                      <>
                        <Button
                           classNames="d-none d-md-block"
                           context="primary"
                           external
                           linkProps={{ to: `${WEB_APP_URL}/login` }}
                           noArrow
                           noNewTab
                           small
                        >
                          <FormattedMessage id="header.sign_in" />
                        </Button>
                      </>
                    )}
                  </>
                ) : null}

                {/* <div className="navbar__language-selector d-none d-lg-block">
                  <LocaleSelect locale={locale} />
                </div> */}
                 
              </div>

              <div className="navbar__menu-container d-md-none">
                <div className="navbar__menu-wrapper">
                  <div
                    className="navbar__menu-toggler d-md-none"
                    role="button"
                    tabIndex={0}
                    onClick={handleMobileMenuTogglerClick}
                    onKeyDown={handleMobileMenuTogglerKeyDown}
                  >
                    <img
                      alt="Menu"
                      className="navbar__menu-icon"
                      src={menuIcon}
                    />
                    <span className="navbar__menu-toggler-text d-none d-sm-block">
                      Menu
                    </span>
                  </div>
                  <OffScreenSlider
                    show={showMobileMenuSlider}
                    side="right"
                    onClose={handleMobileMenuClose}
                  >
                    {showLanguageOptionsList ? (
                      <ul className="navbar__menu-list">
                        <li
                          className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--has-back-chevron"
                          onClick={handleHideLanguageOptionsList}
                        >
                          <FormattedMessage id="header.back" />
                        </li>
                        <LocaleSelect
                          locale={locale}
                          showAsList
                        />
                      </ul>
                    ) : (
                      <ul className="navbar__menu-list">
                        <div  className="navbar__close-icon">
                          <img
                            alt="Close Icon"
                            src={closeIcon}
                            onClick={handleMobileMenuClose}
                          />
                        </div>
                        <Link
                          className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--no-background"
                          to="/"
                        >
                          <FormattedMessage id="header.navigation_links.home" />
                        </Link>
                        <Link
                          className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--no-background"
                          to="/expertise"
                        >
                          <FormattedMessage id="header.navigation_links.expertise" />
                        </Link>
                       
                        <Link
                          className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--no-background"
                          to="/products"
                        >
                          <FormattedMessage id="header.navigation_links.products" />
                        </Link>
                        <Link
                          className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--no-background"
                          to="/about"
                        >
                          <FormattedMessage id="header.navigation_links.about" />
                        </Link>
                        <Link
                          className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--no-background"
                          to="/contacts"
                        >
                          <FormattedMessage id="header.navigation_links.contact" />
                        </Link>
                        
                        {isUserAuthenticated ? (
                          <>
                            <li className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item__user">
                              {(!!userImageUrl || !!avatarImage) && (
                                <div className="navbar__mobile-menu-list-item__avatar">
                                  <img
                                    alt={userName}
                                    className="navbar__mobile-menu-list-item__avatar-image"
                                    src={userImageUrl || avatarImage}
                                  />
                                </div>
                              )}
                              <div className="navbar__mobile-menu-list-item__user-info">
                                <div className="navbar__mobile-menu-list-item__user-greeting">
                                  <FormattedMessage
                                    id="header.greeting"
                                    values={{ name: userName }}
                                  />
                                </div>
                                {!FormattedMessage && (
                                  <>
                                    <span className="navbar__mobile-menu-list-item__user-greeting">
                                      Hello
                                      {`${userName ? ',\xa0' : ''}`}
                                    </span>
                                    {userName || null}
                                  </>
                                )}
                                <div className="navbar__mobile-menu-list-item__user-email">
                                  {userEmail}
                                </div>
                              </div>
                            </li>
                            {/* <li
                              className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--has-chevron"
                              onClick={handleShowLanguageOptionsList}
                            >
                              <img
                                alt="Language Icon"
                                className="navbar__mobile-menu-list-item__language-icon"
                                src={currentLanguageIcon}
                              />
                              <span>
                                {currentLanguageText}
                              </span>
                            </li> */}
                          </>
                        ) : (
                          <>
                            <a
                              className="navbar__mobile-menu-list-item navbar__mobile-menu-list-link--orange-text"
                              href={`${WEB_APP_URL}/login`}
                            >
                              <FormattedMessage id="header.sign_in" />
                            </a>
                           {/*  <li
                              className="navbar__mobile-menu-list-item navbar__mobile-menu-list-item--has-chevron"
                              onClick={handleShowLanguageOptionsList}
                            >
                              <img
                                alt="Language Icon"
                                className="navbar__mobile-menu-list-item__language-icon"
                                src={currentLanguageIcon}
                              />
                              <span>
                                {currentLanguageText}
                              </span>
                            </li> */}
                          </>
                        )}

                      </ul>
                    )}
                  </OffScreenSlider>
                </div>
              </div>

            </div>    
          
          </div>
        </div>
      </header>
    </>
  );
};

Navbar.propTypes = {
  locale: PropTypes.string
};

Navbar.defaultProps = {
  locale: 'en'
};

export default Navbar;
