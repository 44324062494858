import React from 'react';
import { FormattedMessage } from 'react-intl';

import DownloadIosImage from 'images/home/download-ios.inline.svg';
import DownloadAndroidImage from 'images/home/download-android.inline.svg';

import './styles.scss';

const GetAppCard = () => (
  <div className="get-app-card">
    <div className="get-app-card__title">
      <FormattedMessage id="get_app_card.title" />
    </div>
    <div className="get-app-card__text">
      <FormattedMessage id="get_app_card.description" />
    </div>
    <div className="get-app-card__download-buttons">
      <a
        className="get-app-card__download-link"
        href="https://itunes.apple.com/pt/app/youship/id1077762019?l=pt&ls=1&mt=8"
        rel="noreferrer noopener"
        target="_blank"
      >
        <DownloadIosImage />
      </a>
      <a
        className="get-app-card__download-link"
        href="https://play.google.com/store/apps/details?id=com.shipnow.youship"
        rel="noreferrer noopener"
        target="_blank"
      >
        <DownloadAndroidImage />
      </a>
    </div>
  </div>
);

export default GetAppCard;
