import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import locales from '@youship/i18n/locales';

import SharedLocaleSelect from '@youship/components/locale-select';
import SessionStorageManager from '@youship/utils/SessionStorageManager';

const localeKeys = Object.keys(locales);

const LocaleSelect = ({ classNames, locale, showAsList }) => {
  const defaultLocaleKey = localeKeys.find(key => locales[key].default);

  const handleChange = (value) => {
    SessionStorageManager.setLocale(value);

    if (value !== locale) {
      const { location } = window;
      let locationPathname = location.pathname;

      if (locale !== defaultLocaleKey) locationPathname = locationPathname.replace(`/${locale}`, '');

      let newLocation = `${locationPathname}${locationPathname.endsWith('/') ? '' : '/'}`;

      if (value !== defaultLocaleKey) newLocation = `/${value}${newLocation}`;

      navigate(newLocation);
    }
  };

  return (
    <SharedLocaleSelect
      classNames={classNames}
      selected={locale}
      showLanguageList={showAsList}
      onChange={handleChange}
    />
  );
};

LocaleSelect.propTypes = {
  classNames: PropTypes.string,
  locale: PropTypes.string
};

LocaleSelect.defaultProps = {
  classNames: null,
  locale: 'en'
};

export default LocaleSelect;
