
// TODO: Move cookies code out of storage
//import Cookies from 'universal-cookie';
import Cookies from './../../../node_modules/universal-cookie'



const COOKIES_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN || process.env.GATSBY_COOKIES_DOMAIN;
const LOCALE = 'LOCALE';

const cookies = new Cookies();

// Keys for interacting with sessionStorage:
const REDIRECT_URL = 'redirect_url';
const API_BASE_URL = 'api_base_url';
const AUTHENTICATION_REQUIRE_PHONE_ACTIVATION = 'auth_require_phone_activation';
const AUTHENTICATION_TOKEN = 'auth_token';
const AUTHENTICATION_TOKEN_EXPIRATION_TIME = 'auth_token_exp';
const CHECKOUT_CONTACT_NAME = 'checkout_contact_name';
const CHECKOUT_CONTACT_PHONE_COUNTRY_CODE = 'checkout_contact_phone_country_code';
const CHECKOUT_CONTACT_PHONE_NUMBER = 'checkout_contact_phone_number';
const CHECKOUT_DROPOFF_ADDRESS = 'checkout_dropoff_address';
const CHECKOUT_DROPOFF_SCHEDULE = 'checkout_dropoff_schedule';
const CHECKOUT_PAYMENT_TYPE_ID = 'checkout_paymment_type_id';
const CHECKOUT_VOUCHER_CODE = 'checkout_voucher_code';
const DELIVERY_DROPOFFS = 'delivery_dropoffs';
const DELIVERY_DESTINATION_SCHEDULE = 'delivery_destination_schedule';
const DELIVERY_ORIGIN_SCHEDULE = 'delivery_origin_schedule';
const DELIVERY_PAYMENT_TYPE_ID = 'delivery_paymment_type_id';
const DELIVERY_PICKUPS = 'delivery_pickups';
const DELIVERY_PICKUP_ADDRESS = 'delivery_pickup_address';
const DELIVERY_PICKUP_CONTACT_COMPANY = 'delivery_pickup_contact_company';
const DELIVERY_PICKUP_CONTACT_NAME = 'delivery_pickup_contact_name';
const DELIVERY_PICKUP_CONTACT_PHONE_COUNTRY_CODE = 'delivery_pickup_contact_phone_country_code';
const DELIVERY_PICKUP_CONTACT_PHONE_NUMBER = 'delivery_pickup_contact_phone_number';
const DELIVERY_PICKUP_NOTES = 'delivery_pickup_notes';
const DELIVERY_SERVICE_CODE = 'delivery_service_code';
const DELIVERY_SHIPMENTS = 'delivery_shipments';
const DELIVERY_SHIPMENTS_OPTIONS = 'delivery_shipments_options';
const DELIVERY_SHIPMENT_METHOD = 'delivery_shipment_method';
const DELIVERY_REQUEST_TYPE = 'delivery_resquest_type';
const DELIVERY_DRAFT_REF    = 'delivery_draft_ref';
const DELIVERY_STOPS = 'delivery_stops';
const DELIVERY_VOUCHER_CODE = 'delivery_voucher_code';
const DELIVERY_HAS_RETURN = 'delivery_has_return';
const SHOPPING_CART_ITEMS = 'shopping_cart_items';
const SHOPPING_CART_SHOP_ID = 'shopping_cart_shop_id';
const USER_PROFILE = 'profile';
const CURRENT_DELIVERIES = 'current_deliveries';
const ACTIVE_DELIVERIES = 'active_deliveries';
const HISTORY_DELIVERIES = 'history_deliveries';
const DRAFT_DELIVERIES = 'drafts_deliveries';
const ALL_DELIVERIES = 'all_deliveries';
const DEFAULT_DELIVERY_ADDRESS  = 'default_delivery_address';
const DEFAULT_DELIVERY_TYPE     = 'default_delivery_type';
const DEFAULT_DELIVERY_SCHEDULE = 'default_delivery_schedule';

export default class SessionStorageManager {
  // API: Base URL

  static setApiBaseUrl (apiBaseUrl) {
    sessionStorage.setItem(API_BASE_URL, apiBaseUrl);
  }

  static getApiBaseUrl () {
    const value = sessionStorage.getItem(API_BASE_URL);

    if (value === 'null') return null;

    return value;
  }

  static removeApiBaseUrl () {
    sessionStorage.removeItem(API_BASE_URL);
  }

  // Redirect URL

  static setRedirectUrl (requirePhoneActivation) {
    sessionStorage.setItem(REDIRECT_URL, requirePhoneActivation);
  }

  static getRedirectUrl () {
    return sessionStorage.getItem(REDIRECT_URL);
  }

  static removeRedirectUrl () {
    sessionStorage.removeItem(REDIRECT_URL);
  }

  // Authentication: Require phone activation

  static setAuthenticationRequirePhoneActivation (requirePhoneActivation) {
    sessionStorage.setItem(AUTHENTICATION_REQUIRE_PHONE_ACTIVATION, requirePhoneActivation);
  }

  static getAuthenticationRequirePhoneActivation () {
    const requirePhoneActivation = sessionStorage.getItem(AUTHENTICATION_REQUIRE_PHONE_ACTIVATION);

    if (requirePhoneActivation === 'true') return true;
    if (requirePhoneActivation === 'false') return false;

    return null;
  }

  static removeAuthenticationRequirePhoneActivation () {
    sessionStorage.removeItem(AUTHENTICATION_REQUIRE_PHONE_ACTIVATION);
  }

  // Authentication: Token

  static setAuthenticationToken (authenticationToken) {
    sessionStorage.setItem(AUTHENTICATION_TOKEN, authenticationToken);
  }

  static getAuthenticationToken () {
    if (typeof window !== 'undefined') {
      const value = sessionStorage.getItem(AUTHENTICATION_TOKEN);

      if (value === 'null') return null;

      return value;
    }

    return null;
  }

  static removeAuthenticationToken () {
    sessionStorage.removeItem(AUTHENTICATION_TOKEN);
  }

  // Authentication: Token expiration time

  static setAuthenticationTokenExpirationTime (tokenExpirationTime) {
    sessionStorage.setItem(AUTHENTICATION_TOKEN_EXPIRATION_TIME, tokenExpirationTime);
  }

  static getAuthenticationTokenExpirationTime () {
    return sessionStorage.getItem(AUTHENTICATION_TOKEN_EXPIRATION_TIME);
  }

  static removeAuthenticationTokenExpirationTime () {
    sessionStorage.removeItem(AUTHENTICATION_TOKEN_EXPIRATION_TIME);
  }

  // Checkout: Contact name

  static setCheckoutContactName (tokenExpirationTime) {
    sessionStorage.setItem(CHECKOUT_CONTACT_NAME, tokenExpirationTime);
  }

  static getCheckoutContactName () {
    const value = sessionStorage.getItem(CHECKOUT_CONTACT_NAME);

    if (value === 'null') return null;

    return value;
  }

  static removeCheckoutContactName () {
    sessionStorage.removeItem(CHECKOUT_CONTACT_NAME);
  }

  // Checkout: Contact phone country code

  static setCheckoutContactPhoneCountryCode (tokenExpirationTime) {
    sessionStorage.setItem(CHECKOUT_CONTACT_PHONE_COUNTRY_CODE, tokenExpirationTime);
  }

  static getCheckoutContactPhoneCountryCode () {
    const value = sessionStorage.getItem(CHECKOUT_CONTACT_PHONE_COUNTRY_CODE);

    if (value === 'null') return null;

    return value;
  }

  static removeCheckoutContactPhoneCountryCode () {
    sessionStorage.removeItem(CHECKOUT_CONTACT_PHONE_COUNTRY_CODE);
  }

  // Checkout: Contact phone number

  static setCheckoutContactPhoneNumber (tokenExpirationTime) {
    sessionStorage.setItem(CHECKOUT_CONTACT_PHONE_NUMBER, tokenExpirationTime);
  }

  static getCheckoutContactPhoneNumber () {
    const value = sessionStorage.getItem(CHECKOUT_CONTACT_PHONE_NUMBER);

    if (value === 'null') return null;

    return value;
  }

  static removeCheckoutContactPhoneNumber () {
    sessionStorage.removeItem(CHECKOUT_CONTACT_PHONE_NUMBER);
  }

  // Checkout: Dropoff address

  static setCheckoutDropoffAddress (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(CHECKOUT_DROPOFF_ADDRESS, stringified);
  }

  static getCheckoutDropoffAddress () {
    const stored = sessionStorage.getItem(CHECKOUT_DROPOFF_ADDRESS);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeCheckoutDropoffAddress () {
    sessionStorage.removeItem(CHECKOUT_DROPOFF_ADDRESS);
  }

  // Checkout: Dropoff schedule

  static setCheckoutDropoffSchedule (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(CHECKOUT_DROPOFF_SCHEDULE, stringified);
  }

  static getCheckoutDropoffSchedule () {
    const stored = sessionStorage.getItem(CHECKOUT_DROPOFF_SCHEDULE);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeCheckoutDropoffSchedule () {
    sessionStorage.removeItem(CHECKOUT_DROPOFF_SCHEDULE);
  }

  // Delivery: Has return

  static setDeliveryHasReturn (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(DELIVERY_HAS_RETURN, stringified);
  }

  static getDeliveryHasReturn () {
    const stored = sessionStorage.getItem(DELIVERY_HAS_RETURN);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeDeliveryHasReturn () {
    sessionStorage.removeItem(DELIVERY_HAS_RETURN);
  }

  // Checkout: Payment type id

  static setCheckoutPaymentTypeId (value) {
    sessionStorage.setItem(CHECKOUT_PAYMENT_TYPE_ID, value);
  }

  static getCheckoutPaymentTypeId () {
    const value = sessionStorage.getItem(CHECKOUT_PAYMENT_TYPE_ID);

    if (value === 'null') return null;

    return value;
  }

  static removeCheckoutPaymentTypeId () {
    sessionStorage.removeItem(CHECKOUT_PAYMENT_TYPE_ID);
  }

  // Checkout: Voucher code

  static setCheckoutVoucherCode (value) {
    sessionStorage.setItem(CHECKOUT_VOUCHER_CODE, value);
  }

  static getCheckoutVoucherCode () {
    const value = sessionStorage.getItem(CHECKOUT_VOUCHER_CODE);

    if (value === 'null') return null;

    return value;
  }

  static removeCheckoutVoucherCode () {
    sessionStorage.removeItem(CHECKOUT_VOUCHER_CODE);
  }

  // Delivery: Pickups

  static setDeliveryPickups (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(DELIVERY_PICKUPS, stringified);
  }

  static getDeliveryPickups () {
    const stored = sessionStorage.getItem(DELIVERY_PICKUPS);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeDeliveryPickups () {
    sessionStorage.removeItem(DELIVERY_PICKUPS);
  }

  // Delivery: Pickup contact name

  static setDeliveryPickupContactName (tokenExpirationTime) {
    sessionStorage.setItem(DELIVERY_PICKUP_CONTACT_NAME, tokenExpirationTime);
  }

  static getDeliveryPickupContactName () {
    const value = sessionStorage.getItem(DELIVERY_PICKUP_CONTACT_NAME);

    if (value === 'null') return null;

    return value;
  }

  static removeDeliveryPickupContactName () {
    sessionStorage.removeItem(DELIVERY_PICKUP_CONTACT_NAME);
  }

  // Delivery: Pickup contact company

  static setDeliveryPickupContactCompany (tokenExpirationTime) {
    sessionStorage.setItem(DELIVERY_PICKUP_CONTACT_COMPANY, tokenExpirationTime);
  }

  static getDeliveryPickupContactCompany () {
    const value = sessionStorage.getItem(DELIVERY_PICKUP_CONTACT_COMPANY);

    if (value === 'null') return null;

    return value;
  }

  static removeDeliveryPickupContactCompany () {
    sessionStorage.removeItem(DELIVERY_PICKUP_CONTACT_COMPANY);
  }

  // Delivery: Pickup contact phone country code

  static setDeliveryPickupContactPhoneCountryCode (tokenExpirationTime) {
    sessionStorage.setItem(DELIVERY_PICKUP_CONTACT_PHONE_COUNTRY_CODE, tokenExpirationTime);
  }

  static getDeliveryPickupContactPhoneCountryCode () {
    const value = sessionStorage.getItem(DELIVERY_PICKUP_CONTACT_PHONE_COUNTRY_CODE);

    if (value === 'null') return null;

    return value;
  }

  static removeDeliveryPickupContactPhoneCountryCode () {
    sessionStorage.removeItem(DELIVERY_PICKUP_CONTACT_PHONE_COUNTRY_CODE);
  }

  // Delivery: Pickup contact phone number

  static setDeliveryPickupContactPhoneNumber (tokenExpirationTime) {
    sessionStorage.setItem(DELIVERY_PICKUP_CONTACT_PHONE_NUMBER, tokenExpirationTime);
  }

  static getDeliveryPickupContactPhoneNumber () {
    const value = sessionStorage.getItem(DELIVERY_PICKUP_CONTACT_PHONE_NUMBER);

    if (value === 'null') return null;

    return value;
  }

  static removeDeliveryPickupContactPhoneNumber () {
    sessionStorage.removeItem(DELIVERY_PICKUP_CONTACT_PHONE_NUMBER);
  }

  // Delivery: Dropoffs

  static setDeliveryDropoffs (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(DELIVERY_DROPOFFS, stringified);
  }

  static getDeliveryDropoffs () {
    const stored = sessionStorage.getItem(DELIVERY_DROPOFFS);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeDeliveryDropoffs () {
    sessionStorage.removeItem(DELIVERY_DROPOFFS);
  }

  // Delivery: Destination schedule

  static setDeliveryDestinationSchedule (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(DELIVERY_DESTINATION_SCHEDULE, stringified);
  }

  static getDeliveryDestinationSchedule () {
    const stored = sessionStorage.getItem(DELIVERY_DESTINATION_SCHEDULE);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeDeliveryDestinationSchedule () {
    sessionStorage.removeItem(DELIVERY_DESTINATION_SCHEDULE);
  }

  // Delivery: Pickup address

  static setDeliveryPickupAddress (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(DELIVERY_PICKUP_ADDRESS, stringified);
  }

  static getDeliveryPickupAddress () {
    const stored = sessionStorage.getItem(DELIVERY_PICKUP_ADDRESS);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeDeliveryPickupAddress () {
    sessionStorage.removeItem(DELIVERY_PICKUP_ADDRESS);
  }

  // Delivery: Pickup notes

  static setDeliveryPickupNotes (tokenExpirationTime) {
    sessionStorage.setItem(DELIVERY_PICKUP_NOTES, tokenExpirationTime);
  }

  static getDeliveryPickupNotes () {
    const value = sessionStorage.getItem(DELIVERY_PICKUP_NOTES);

    if (value === 'null') return null;

    return value;
  }

  static removeDeliveryPickupNotes () {
    sessionStorage.removeItem(DELIVERY_PICKUP_NOTES);
  }

  // Delivery: Origin schedule

  static setDeliveryOriginSchedule (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(DELIVERY_ORIGIN_SCHEDULE, stringified);
  }

  static getDeliveryOriginSchedule () {
    const stored = sessionStorage.getItem(DELIVERY_ORIGIN_SCHEDULE);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeDeliveryOriginSchedule () {
    sessionStorage.removeItem(DELIVERY_ORIGIN_SCHEDULE);
  }

  // Delivery: Service Code

  static setDeliveryServiceCode (value) {
    sessionStorage.setItem(DELIVERY_SERVICE_CODE, value);
  }

  static getDeliveryServiceCode () {
    const value = sessionStorage.getItem(DELIVERY_SERVICE_CODE);

    if (value === 'null') return null;

    return value;
  }

  static removeDeliveryServiceCode () {
    sessionStorage.removeItem(DELIVERY_SERVICE_CODE);
  }

  // Delivery: Stops

  static setDeliveryStops (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(DELIVERY_STOPS, stringified);
  }

  static getDeliveryStops () {
    const stored = sessionStorage.getItem(DELIVERY_STOPS);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeDeliveryStops () {
    sessionStorage.removeItem(DELIVERY_STOPS);
  }

  // Delivery: Shipments

  static setDeliveryShipments (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(DELIVERY_SHIPMENTS, stringified);
  }

  static getDeliveryShipments () {
    const stored = sessionStorage.getItem(DELIVERY_SHIPMENTS);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeDeliveryShipments () {
    sessionStorage.removeItem(DELIVERY_SHIPMENTS);
  }

  // Delivery: ShipmentsOptions

  static setDeliveryShipmentsOptions (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(DELIVERY_SHIPMENTS_OPTIONS, stringified);
  }

  static getDeliveryShipmentsOptions () {
    const stored = sessionStorage.getItem(DELIVERY_SHIPMENTS_OPTIONS);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeDeliveryShipmentsOptions () {
    sessionStorage.removeItem(DELIVERY_SHIPMENTS_OPTIONS);
  }

  // Delivery: Service Code

  static setDeliveryShipmentMethod (value) {
    sessionStorage.setItem(DELIVERY_SHIPMENT_METHOD, value);
  }

  static getDeliveryShipmentMethod () {
    const value = sessionStorage.getItem(DELIVERY_SHIPMENT_METHOD);

    if (value === 'null') return null;

    return value;
  }

  static removeDeliveryShipmentMethod () {
    sessionStorage.removeItem(DELIVERY_SHIPMENT_METHOD);
  }

  //DELIVERY_REQUEST_TYPE
  static setDeliveryRequestType (value) {
    sessionStorage.setItem(DELIVERY_REQUEST_TYPE, value);
  }

  static getDeliveryRequestType () {
    const value = sessionStorage.getItem(DELIVERY_REQUEST_TYPE);

    if (value === 'null') return null;

    return value;
  }

  static removeDeliveryRequestType () {
    sessionStorage.removeItem(DELIVERY_REQUEST_TYPE);
  }

  //DELIVERY_DRAFT_REF
  static setDeliveryDraftRef (value) {
    sessionStorage.setItem(DELIVERY_DRAFT_REF, value);
  }

  static getDeliveryDraftRef () {
    const value = sessionStorage.getItem(DELIVERY_DRAFT_REF);

    if (value === 'null') return null;

    return value;
  }

  static removeDeliveryDraftRef () {
    sessionStorage.removeItem(DELIVERY_DRAFT_REF);
  }

  // Delivery: Payment type id

  static setDeliveryPaymentTypeId (value) {
    sessionStorage.setItem(DELIVERY_PAYMENT_TYPE_ID, value);
  }

  static getDeliveryPaymentTypeId () {
    const value = sessionStorage.getItem(DELIVERY_PAYMENT_TYPE_ID);

    if (value === 'null') return null;

    return value;
  }

  static removeDeliveryPaymentTypeId () {
    sessionStorage.removeItem(DELIVERY_PAYMENT_TYPE_ID);
  }

  // Delivery: Voucher code

  static setDeliveryVoucherCode (value) {
    sessionStorage.setItem(DELIVERY_VOUCHER_CODE, value);
  }

  static getDeliveryVoucherCode () {
    const value = sessionStorage.getItem(DELIVERY_VOUCHER_CODE);

    if (value === 'null') return null;

    return value;
  }

  static removeDeliveryVoucherCode () {
    sessionStorage.removeItem(DELIVERY_VOUCHER_CODE);
  }

  // Locale

  static setLocale (locale) {
    cookies.set(LOCALE, locale, { path: '/', maxAge: 60 * 60 * 24 * 365*5, domain: COOKIES_DOMAIN });
  }

  static getLocale () {
    const cookieLocale = cookies.get(LOCALE);
    let language = navigator?.language;

    if (language) {
      if (language === 'pt-PT' || language === 'pt-BR') {
        language = 'pt';
      } else {
        language = 'en';
      }
    }

    return cookieLocale || language;
  }

  static getLocaleCookie () {
    return cookies.get(LOCALE);
  }

  static removeLocale () {
    cookies.remove(LOCALE, { path: '/', domain: COOKIES_DOMAIN });
  }

  // Shopping Cart: items

  static setShoppingCartItems (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(SHOPPING_CART_ITEMS, stringified);
  }

  static getShoppingCartItems () {
    const stored = sessionStorage.getItem(SHOPPING_CART_ITEMS);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeShoppingCartItems () {
    sessionStorage.removeItem(SHOPPING_CART_ITEMS);
  }

  // Shopping Cart: shop id (shop_code)

  static setShoppingCartShopId (value) {
    sessionStorage.setItem(SHOPPING_CART_SHOP_ID, value);
  }

  static getShoppingCartShopId () {
    const value = sessionStorage.getItem(SHOPPING_CART_SHOP_ID);

    if (value === 'null') return null;

    return value;
  }

  static removeShoppingCartShopId () {
    sessionStorage.removeItem(SHOPPING_CART_SHOP_ID);
  }

  // User profile

  static setUserProfile (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(USER_PROFILE, stringified);
  }

  static getUserProfile () {
    const stored = sessionStorage.getItem(USER_PROFILE);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeUserProfile () {
    sessionStorage.removeItem(USER_PROFILE);
  }

  // User: Current Deliveries

  static setCurrentDeliveries (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(CURRENT_DELIVERIES, stringified);
  }

  static getCurrentDeliveries () {
    const stored = sessionStorage.getItem(CURRENT_DELIVERIES);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeCurrentDeliveries () {
    sessionStorage.removeItem(CURRENT_DELIVERIES);
  }

  // User: Active Deliveries

  static setActiveDeliveries (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(ACTIVE_DELIVERIES, stringified);
  }

  static getActiveDeliveries () {
    const stored = sessionStorage.getItem(ACTIVE_DELIVERIES);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeActiveDeliveries () {
    sessionStorage.removeItem(ACTIVE_DELIVERIES);
  }

  // User: History Deliveries

  static setHistoryDeliveries (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(HISTORY_DELIVERIES, stringified);
  }

  static getHistoryDeliveries () {
    const stored = sessionStorage.getItem(HISTORY_DELIVERIES);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeHistoryDeliveries () {
    sessionStorage.removeItem(HISTORY_DELIVERIES);
  }

  // User: Draft Deliveries

  static setDraftDeliveries (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(DRAFT_DELIVERIES, stringified);
  }

  static getDraftDeliveries () {
    const stored = sessionStorage.getItem(DRAFT_DELIVERIES);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeDraftDeliveries () {
    sessionStorage.removeItem(DRAFT_DELIVERIES);
  }

  // User: All Deliveries

  static setAllDeliveries (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(ALL_DELIVERIES, stringified);
  }

  static getAllDeliveries () {
    const stored = sessionStorage.getItem(ALL_DELIVERIES);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeAllDeliveries () {
    sessionStorage.removeItem(ALL_DELIVERIES);
  }

  // Nav Bar Address for not logged users

  static setDefaultDeliveryAddress (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(DEFAULT_DELIVERY_ADDRESS, stringified);
  }

  static getDefaultDeliveryAddress () {
    const stored = sessionStorage.getItem(DEFAULT_DELIVERY_ADDRESS);
    //console.log("stored:"+stored);
    const parsed = stored &&  stored !== "undefined" ? JSON.parse(stored) : null;

    return parsed;
  }

  static removeDefaultDeliveryAddress() {
    sessionStorage.removeItem(DEFAULT_DELIVERY_ADDRESS);
  }

  // delivery type: delivery or pickup
  static setDefaultDeliveryType (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(DEFAULT_DELIVERY_TYPE, stringified);
  }

  static getDefaultDeliveryType () {
    const stored = sessionStorage.getItem(DEFAULT_DELIVERY_TYPE);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeDefaultDeliveryType() {
    sessionStorage.removeItem(DEFAULT_DELIVERY_TYPE);
  }

  //Schedule: now, or date/time
  static setDefaultDeliverySchedule (value) {
    const stringified = JSON.stringify(value);

    sessionStorage.setItem(DEFAULT_DELIVERY_SCHEDULE, stringified);
  }

  static getDefaultDeliverySchedule () {
    const stored = sessionStorage.getItem(DEFAULT_DELIVERY_SCHEDULE);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeDefaultDeliverySchedule() {
    sessionStorage.removeItem(DEFAULT_DELIVERY_SCHEDULE);
  }

}
