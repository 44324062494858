import React from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'react-intl';

import locales from '@youship/i18n/locales';

const LocalizedLink = ({ intl: { locale }, to, ...props }) => {
  let path = to;

  if (locales[locale] && !locales[locale].default) path = `/${locale}${to === '/' ? '' : to}`;

  return <Link {...props} to={path} />;
};

export default injectIntl(LocalizedLink);
